import { Accessor, Component, createEffect, createSignal, For, onCleanup, onMount } from 'solid-js'
import { getCurrentQueryParams, modifyQueryParams } from '../utils/routing'
import { Pagination as PaginationArk, PaginationPageChangeDetails, UsePaginationContext } from '@ark-ui/solid'

interface PaginationProps {
	currentPage: number
	totalPageCount: number
	totalCount: number
	pageSize: number
	siblingCount?: number
	isLoading: boolean
	onPageChange: (page: number) => void
	pageIdentifier?: string
}

export const Pagination: Component<PaginationProps> = props => {
	const [windowRect, setRect] = createSignal({ height: window.innerHeight, width: window.innerWidth })
	// eslint-disable-next-line solid/reactivity
	const [currentPage, setCurrentPage] = createSignal(props.currentPage)
	let queryParams = getCurrentQueryParams()
	const page = queryParams.get('page')

	// props.currentPage is not reactive, so we need to set it manually, but only once
	createEffect(
		() => {
			setCurrentPage(props.currentPage)
		},
		{ defer: true }
	)

	const pageIdentifier = () => props.pageIdentifier ?? 'page'

	// Load page from query params
	if (page && parseInt(page)) {
		/* eslint-disable-next-line */
		props.onPageChange(parseInt(page))
	}
	const totalPageCount = () => props.totalPageCount

	createEffect((previous: number) => {
		if (previous === currentPage()) {
			return currentPage()
		}

		// Refetch query params, they might have changed
		queryParams = getCurrentQueryParams()

		if (currentPage() === 0) {
			queryParams.delete(pageIdentifier())
		} else {
			queryParams.set(pageIdentifier(), currentPage().toString())
		}

		modifyQueryParams(queryParams)

		return currentPage()
	}, 0)

	const jumpPage = (pageNumber: number) => {
		if (window.matchMedia('(min-width: 320px)').matches) {
			props.onPageChange(pageNumber)
		}
	}
	const nextPage = () => {
		if (hasNextPage()) {
			props.onPageChange(currentPage() + 1)
		}
	}
	const prevPage = () => {
		if (hasPreviousPage()) {
			props.onPageChange(currentPage() - 1)
		}
	}

	const hasNextPage = (): boolean => {
		return currentPage() < totalPageCount() - 1
	}

	const hasPreviousPage = (): boolean => {
		return currentPage() > 0
	}

	const handler = (_event: Event) => {
		setRect({ height: window.innerHeight, width: window.innerWidth })
	}

	const calcSiblingCount = () => {
		if (windowRect().width <= 319) {
			return 0
		} else if (windowRect().width <= 575) {
			return 1
		} else if (windowRect().width <= 767) {
			return 2
		}
		return 3
	}

	onMount(() => {
		window.addEventListener('resize', handler)
	})

	onCleanup(() => {
		window.removeEventListener('resize', handler)
	})

	return (
		<PaginationArk.Root
			class="pagination pagination__control"
			page={currentPage() + 1}
			count={props.totalCount}
			pageSize={props.pageSize}
			siblingCount={props.siblingCount ?? calcSiblingCount()}
			onPageChange={(_page: PaginationPageChangeDetails) => {
				// setCurrentPage(_page.page - 1)
				props.onPageChange(_page.page - 1)
			}}>
			<PaginationArk.PrevTrigger class="pagination__control prev">
				<span class="not_mobile">Zurück</span>
			</PaginationArk.PrevTrigger>
			<div class="pagination__pages">
				<PaginationArk.Context>
					{(api: UsePaginationContext) => (
						<For each={api().pages}>
							{(_page: { type: 'ellipsis' } | { type: 'page'; value: number }, index: Accessor<number>) =>
								_page.type === 'page' ? (
									<PaginationArk.Item
										class="pagination__item"
										{..._page}
										onClick={() => {
											jumpPage(_page.value - 1)
										}}>
										{_page.value}
									</PaginationArk.Item>
								) : (
									<PaginationArk.Ellipsis index={index()}>&#8230;</PaginationArk.Ellipsis>
								)
							}
						</For>
					)}
				</PaginationArk.Context>
			</div>
			<PaginationArk.NextTrigger class="pagination__control next">
				<span class="not_mobile">Weiter</span>
			</PaginationArk.NextTrigger>
		</PaginationArk.Root>
	)
}

export default Pagination
