const getCurrentQueryParams = () => {
	return new URLSearchParams(window.location.search)
}

const modifyQueryParams = (params: URLSearchParams) => {
	const stringifiedParams = params.toString()

	if (stringifiedParams !== '') {
		window.history.replaceState({}, '', `${window.location.pathname}?${stringifiedParams}`)
		return
	}

	window.history.replaceState({}, '', `${window.location.pathname}`)
}

export { getCurrentQueryParams, modifyQueryParams }
